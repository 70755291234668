<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <b-row>
            <b-col sm="5">
              <!-- Parameter -->

              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="DatabaseIcon" size="14" />
                    <span class="align-middle ml-50 text-dark">Ingest Data Raw</span>
                  </b-card-title>
                </b-card-header>
                <b-row class="pl-1 pr-1 mt-2">
                  <b-col sm="10">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label=" Category :" label-for="input-sm">
                      <b-form-select v-model="t_station" id="select-station" size="sm">
                        <option :value="option">Pilih</option>
                        <option value="1">Hari Hujan</option>
                        <option value="2">Suhu Udara</option>
                        <option value="3">Tekanan Udara</option>
                        <option value="4">Kelembapan Nisbi Udara</option>
                        <option value="5">Lama Penyinaran Matahari</option>
                        <option value="6">Arah dan Kecepatan Angin</option>
                        <option value="7">Penguapan</option>
                        <option value="7">Curah Hujan</option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col sm="10">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Date :" label-for="input-sm">
                      <a-date-picker @change="onChange" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col sm="10">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label=" Source Data :" label-for="input-sm">
                      <b-form-select v-model="t_station" id="select-station" size="sm">
                        <option :value="option">Pilih</option>
                        <option value="1">API</option>
                        <option value="2">Database</option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1 mt-1">
                  <b-col sm="10">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Url :" label-for="input-sm">
                      <b-form-input id="input-sm" size="sm" placeholder="Auto" />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="pl-1 pr-1">
                  <b-col sm="10">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Server:" label-for="input-sm">
                      <b-form-input id="input-sm" size="sm" placeholder="Input" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col sm="10">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="User :" label-for="input-sm">
                      <b-form-input id="input-sm" size="sm" placeholder="Input" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col sm="10">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Password :" label-for="input-sm">
                      <b-form-input id="input-sm" size="sm" placeholder="Input" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pl-1 pr-1">
                  <b-col sm="10">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Query :" label-for="input-sm">
                      <b-form-textarea id="textarea-default" placeholder="Textarea" rows="3" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <!--  button car1 -->
              <b-button variant="gradient-success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Submit
              </b-button>
            </b-col>

            <b-col sm="7">
              <!-- Parameter TABLE -->
              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="ClipboardIcon" size="14" />
                    <span class="align-middle ml-50 text-dark">Result</span>
                  </b-card-title>
                </b-card-header>
                <b-row class="pl-1 pr-1 mt-2">
                  <b-col>
                    <b-form-group>
                      <b-form-textarea id="textarea-default" placeholder="Textarea" rows="3" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <aaws v-if="choosenForm == 'a'"> </aaws>
    <!-- <aktinograph v-if="choosenForm=='02'"> </aktinograph>
    <thermohigograph v-if="choosenForm=='03'"> </thermohigograph>
    <hujan v-if="choosenForm=='04'"> </hujan>
    <barograph v-if="choosenForm=='05'"> </barograph>
    <perawanan v-if="choosenForm=='06'"> </perawanan>
    <hujan-helman v-if="choosenForm=='07'"> </hujan-helman>
    <kelembaban-tanah v-if="choosenForm=='08'"> </kelembaban-tanah>
    <fenologi v-if="choosenForm=='09'"> </fenologi> -->
  </div>
</template>

<script>
//import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, BTable, BFormTextarea } from "bootstrap-vue";

//import aaws from "./monitoringcomponent/aaws.vue";
// import Aktinograph from "./piascomponent/aktinograph.vue";
// import Barograph from "./piascomponent/barograph.vue";
// import Fenologi from "./piascomponent/fenologi.vue";
// import Hujan from "./piascomponent/hujan.vue";
// import HujanHelman from "./piascomponent/hujanhelman.vue";
// import KelembabanTanah from "./piascomponent/kelembapantanah.vue";
// import Perawanan from "./piascomponent/perawanan.vue";
// import Thermohigograph from "./piascomponent/thermohigoghraph.vue";

// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BTable,
    BFormTextarea,
    //aaws,
    // Aktinograph,
    // Barograph,
    // Fenologi,
    // Hujan,
    // HujanHelman,
    // KelembabanTanah,
    // Perawanan,
    // Thermohigograph,
  },
  data: function() {
    return {
      items: [
        {
          Station_ID: "00040",
          Station_Name: "Station Klimatologi Malang",
          Element: "Value",
          Parameter: "Value",
          year_From: "2020",
          Year_To: "2021",
        },
      ],
    };
  },

  methods: {
    onFetch() {
      this.choosenForm = this.selectedForm;
    },
  },
  // computed: {
  //   nameState() {
  //     return this.name.length > 2;
  //   }
  // },
  //   mounted() {
  //     this.$store.dispatch(SET_BREADCRUMB, [
  //       { title: "Klimatologi", route: "piasmain" },
  //       { title: "PIAS" },
  //     ]);
  //   },
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
</style>
